import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Dates from "../helpers/dates.ts";
import { useLocalStorage } from "../hooks/localStorage.ts";
import API from "./../helpers/api.ts";
import Page from "./../components/page";
import TableView from "../components/tableView";
import Modal from "../components/modal";
import ReadMore from "../components/readMore.tsx";

import { UsersIcon } from "@heroicons/react/24/outline";

const sortOptions = [
  {
    id: "name_asc",
    alt_id: "name",
    title: "Name (A-z)",
    field: "name",
    dir: "asc",
  },
  {
    id: "name_desc",
    title: "Name (Z-a)",
    field: "name",
    dir: "desc",
  },
  {
    id: "follow_up_asc",
    alt_id: "follow_up",
    title: "Follow up",
    field: "follow_up",
    dir: "asc",
  },
  {
    id: "created_at_desc",
    alt_id: "latest",
    title: "Latest",
    field: "created_at",
    dir: "desc",
  },
  {
    id: "created_at_asc",
    alt_id: "oldest",
    title: "Oldest",
    field: "created_at",
    dir: "asc",
  },
  {
    id: "updated_at_desc",
    alt_id: "updated",
    title: "Last update",
    field: "updated_at",
    dir: "desc",
  },
];

const tableHeadings = [
  {
    id: "name",
    title: "Name",
    type: "text",
    width: "max",
  },
  {
    id: "type",
    nested: "title",
    title: "Type",
    type: "badge",
    width: "fit",
  },
  {
    id: "email",
    title: "Email",
    type: "text",
    default: "None",
    width: "fit",
  },
  {
    id: "cell",
    title: "Cell",
    type: "text",
    default: "None",
    width: "fit",
  },
  {
    id: "follow_up_formatted",
    title: "Follow up", // TODO: Find better name, maybe Reminder?
    type: "badge",
    badge_colour: "border-red-500 text-red-500",
    width: "fit",
  },
];

const rowOptions = [
  {
    id: "view",
    title: "View details",
  },
  {
    id: "reminder",
    title: "Edit reminder",
  },
];

const ClientFollowUpModal = ({
  show,
  onClose,
  onConfirm,
  loading,
  data,
}: {
  show: boolean;
  onClose: Function;
  onConfirm: Function;
  loading: boolean;
  data: any;
}) => {
  const [date, setDate] = useState<any>("");
  const [minDate, setMinDate] = useState<any>("");

  useEffect(() => {
    setMinDate(Dates().format("YYYY-MM-DD"));

    if (data?.follow_up) {
      setDate(Dates(data.follow_up).local().format("YYYY-MM-DD"));
    } else {
      setDate("");
    }
  }, [data]);

  const handleSave = () => {
    let follow_up = null;

    if (date) {
      follow_up = Dates(date + " 09:00")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    onConfirm(follow_up);
  };

  return (
    <Modal
      id="edit_client_reminder"
      title="Edit follow up reminder"
      confirmTxt="Save"
      show={show}
      onClose={onClose}
      onConfirm={handleSave}
      loading={loading}
    >
      <p className="mt-1 text-gray-800 dark:text-gray-400">
        Set an email reminder to follow up on{" "}
        <span className="italic font-semibold">{data?.name}.</span>
      </p>
      <div className="mt-3 mb-3">
        <label htmlFor="client_reminder_date" className="sr-only">
          Reminder date
        </label>
        <div className="relative flex">
          <input
            disabled={loading}
            type="date"
            id="client_reminder_date"
            name="reminder date"
            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
            min={minDate}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            autoFocus
            onFocus={(e: any) => (e.nativeEvent.target.defaultValue = "")}
          />
        </div>
      </div>
    </Modal>
  );
};

export default function Clients() {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteDetails, setDeleteDetails] = useState<any>(null);
  const [deletingClient, setDeletingClient] = useState<boolean>(false);

  const [showFollowUpModal, setShowFollowUpModal] = useState<boolean>(false);
  const [followUpDetails, setFollowUpDetails] = useState<any>(null);
  const [editingFollowUp, setEditingFollowUp] = useState<boolean>(false);

  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  const [clients, setClients] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<any>(null);

  const [sortBy, setSortBy] = useLocalStorage("prefs.clients.sort", "name");
  const [sortDir, setSortDir] = useLocalStorage("prefs.clients.dir", "asc");

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    setLoadingClients(true);

    API.clients
      .list(currentPage, sortBy, sortDir, searchTerm, signal)
      .then((res: any) => {
        res.data.forEach((data: any) => {
          if (data.follow_up) {
            data.follow_up_formatted = Dates(data.follow_up)
              .local()
              .format("ll");
          }
        });

        setPagination(res.pagination);
        setClients(res.data);
        setLoadingClients(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load client list");
      });

    return () => {
      controller.abort();
    };
  }, [refresh, currentPage, sortBy, sortDir, searchTerm]);

  const handleSort = (sortValue: any) => {
    setSortBy(sortValue.field);
    setSortDir(sortValue.dir);

    setCurrentPage(1);
  };

  const handleRowOption = (optionId: string, clientDetails: any) => {
    if (optionId === "view") {
      navigate(`/clients/${clientDetails?.id}`, {
        state: { cachedDetails: clientDetails },
      });
    } else if (optionId === "reminder") {
      setFollowUpDetails(clientDetails);
      setShowFollowUpModal(true);
    }
  };

  const handleDeleteClient = (clientDetails: any) => {
    setDeleteDetails(clientDetails);
    setShowDeleteModal(true);
  };

  const onDeleteClient = () => {
    setDeletingClient(true);

    API.clients
      .delete(deleteDetails?.id)
      .then(() => {
        setRefresh(!refresh);
        setCurrentPage(1);
        setShowDeleteModal(false);
        setDeleteDetails(null);
        setDeletingClient(false);
        toast.success("Client deleted successfully");
      })
      .catch(() => {
        setShowDeleteModal(false);
        setDeleteDetails(null);
        setDeletingClient(false);
        toast.error("Failed to delete client");
      });
  };

  const onEditFollowUp = (newDate: string | null) => {
    setEditingFollowUp(true);

    API.clients
      .update(followUpDetails?.id, { follow_up: newDate })
      .then(() => {
        setRefresh(!refresh);
        setShowFollowUpModal(false);
        setFollowUpDetails(null);
        setEditingFollowUp(false);
      })
      .catch(() => {
        setShowFollowUpModal(false);
        setFollowUpDetails(null);
        setEditingFollowUp(false);
        toast.error("Failed to update follow up reminder");
      });
  };

  const handleAddNewClient = () => {
    navigate("/clients/new");
  };

  return (
    <Page
      title="Clients"
      sort={sortOptions}
      handleSort={handleSort}
      currentSort={`${sortBy}_${sortDir}`}
      onCreate={handleAddNewClient}
      createTxt="Add client"
      searchHint="Search for clients"
      handleSearch={setSearchTerm}
      currentSearch={searchTerm}
    >
      <Modal
        id="delete_client"
        title="Delete client"
        confirmTxt="Delete"
        confirmStyle="bg-red-500 hover:bg-red-600"
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onDeleteClient}
        loading={deletingClient}
      >
        <p className="mt-1 text-gray-800 dark:text-gray-400">
          Are you sure you want to remove{" "}
          <span className="italic font-semibold">{deleteDetails?.name}</span>{" "}
          from your list of clients?
        </p>
        <ReadMore id="delete_client" key={deleteDetails?.id}>
          <p>
            Removing this client will also delete any properties they own and
            related viewings.
          </p>
        </ReadMore>
      </Modal>
      <ClientFollowUpModal
        show={showFollowUpModal}
        onClose={() => setShowFollowUpModal(false)}
        onConfirm={onEditFollowUp}
        loading={editingFollowUp}
        data={followUpDetails}
      />
      <TableView
        refreshKey={currentPage}
        headings={tableHeadings}
        loading={loadingClients}
        data={clients}
        options={rowOptions}
        onOption={handleRowOption}
        onDelete={handleDeleteClient}
        emptyStateIcon={<UsersIcon className="h-20 w-20" />}
        emptyStateTitle="No clients added"
        emptyStateDesc="Add a client to get started with client relation management"
        emptyStateActionTitle="Add client"
        emptyStateOnAction={handleAddNewClient}
        pagination={pagination}
        handlePagination={setCurrentPage}
        searchTerm={searchTerm}
        onClearSearch={() => setSearchTerm("")}
      />
    </Page>
  );
}
