import * as Sentry from '@sentry/react';

const callApi = (method: string, url: string, body?:any, headers?:any, signal?:any) => {

  const API_URL = `${import.meta.env.VITE_API_DOMAIN}/${url}`;

  return new Promise((resolve, reject) => {
    fetch(API_URL, {
      method: method,
      ...(body && { body: JSON.stringify(body) }),
      headers: {
        Accept: "application/json",
        ...(body && { "Content-Type": "application/json" }),
        ...headers,
      },
      credentials: "include",
      signal
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          if (response.status.toString()[0] === "5") {
            Sentry.captureException(new Error(`API Error ${response.status}`));
          }

          reject({
            http_status: response.status,
            code: "",
          });
          //error.http_status = response.status;
          //error.code = ""; // TODO: Get from response (response.length && JSON.parse(response)?.code) ? JSON.parse(response)?.code : null;
          //reject(error);
        }
      })
      .then((data: any) => {
        resolve((data?.length > 0) ? JSON.parse(data) : null);
      })
      .catch((error) => {
        if (!(error instanceof DOMException && error.name == "AbortError")) {
          Sentry.captureException(error);
        }

        reject(error);
      });
  });
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`) as Array<any>;

  if (parts.length === 2) return parts.pop().split(";").shift();
  else return null;
};

const getCSRFToken = (signal?: any) => {
  // Handle case of production and staging cookie name clash
  const csfrCookieName = import.meta.env.VITE_ENVIRONMENT === "staging" ? "XSRF-TOKEN-STAGING" : "XSRF-TOKEN";

  return new Promise((resolve, reject) => {
    if (getCookie(csfrCookieName)) {
      resolve(getCookie(csfrCookieName).slice(0, -3));
    } else {
      callApi("GET", "sanctum/csrf-cookie", null, null, signal)
        .then(() => {
          resolve(getCookie(csfrCookieName).slice(0, -3));
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    }
  });
};

const API = {
  auth: {
    login: (email: string, password: string) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "POST",
            "login",
            {
              email: email,
              password: password,
            },
            { "X-XSRF-TOKEN": csrf_token }
          ).then((res: any) => {
            resolve(res.result);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    logout: (signal?: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken(signal).then((csrf_token) => {
          callApi(
            "POST",
            "logout",
            null,
            { "X-XSRF-TOKEN": csrf_token },
            signal
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    getUser: (signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", "user", null, null, signal)
          .then((res: any) => {
            resolve(res.result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  dashboard: {
    listFollowUps: (signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `clients?page=1&order_by=follow_up&order_dir=asc&follow_up=true&limit=3`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    listUpcomingViewings: (signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `viewings?page=1&order_by=starts_at&order_dir=asc&filter=upcoming&limit=3`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
  },
  clients: {
    searchByName: (searchText: string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `clients?limit=10&order_by=name&order_dir=asc&name=${searchText}`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    list: (page?:number, sortBy?:string, sortDir?:string, searchQuery?:string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `clients?page=${page}&order_by=${sortBy}&order_dir=${sortDir}&query=${searchQuery}`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    get: (id: string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `clients/${id}`, null, null, signal)
        .then((data: any) => {
          resolve(data.result);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    add: (data: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "POST",
            "clients",
            data,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    update: (id: string, details: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "PUT",
            `clients/${id}`,
            details,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    delete: (id: string) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "DELETE",
            `clients/${id}`,
            null,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
  },
  clientTypes: {
    list: (searchText: string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `client-types?limit=10&order_by=title&order_dir=asc&title=${searchText}`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    }, 
    add: (data: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "POST",
            "client-types",
            data,
            { "X-XSRF-TOKEN": csrf_token }
          ).then((data: any) => {
              resolve(data.result);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
  },
  properties: {
    searchByTitle: (searchText: string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `properties?limit=10&order_by=title&order_dir=asc&title=${searchText}`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    list: (page?:number, sortBy?:string, sortDir?:string,searchQuery?:string,  signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `properties?page=${page}&order_by=${sortBy}&order_dir=${sortDir}&query=${searchQuery}`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    get: (id: string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `properties/${id}`, null, null, signal)
        .then((data: any) => {
          resolve(data.result);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    add: (data: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "POST",
            "properties",
            data,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    update: (id: string, details: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "PUT",
            `properties/${id}`,
            details,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    delete: (id: string) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "DELETE",
            `properties/${id}`,
            null,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
  },
  viewings: {
    list: (page?:number, sortBy?:string, sortDir?:string, filterBy?:string, searchQuery?:string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `viewings?page=${page}&order_by=${sortBy}&order_dir=${sortDir}&filter=${filterBy}&query=${searchQuery}`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    get: (id: string, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `viewings/${id}`, null, null, signal)
        .then((data: any) => {
          resolve(data.result);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    add: (data: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "POST",
            "viewings",
            data,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    update: (id: string, details: any) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "PUT",
            `viewings/${id}`,
            details,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
    delete: (id: string) => {
      return new Promise(async (resolve, reject) => {
        getCSRFToken().then((csrf_token) => {
          callApi(
            "DELETE",
            `viewings/${id}`,
            null,
            { "X-XSRF-TOKEN": csrf_token }
          ).then(() => {
              resolve(null);
            }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      });
    },
  },
  calendars: {
    list: (page?:number, signal?: any) => {
      return new Promise(async (resolve, reject) => {
        callApi("GET", `calendars?page=${page}`, null, null, signal)
        .then((data: any) => {
          resolve({ data: data.result, pagination: data.pagination });
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
  },
};

export default API;
