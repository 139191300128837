import { Link } from "react-router-dom";
import Logo from "./../assets/logo_icon.png";

import Page from "../components/page";

export default function PageNotFound() {
  return (
    <Page
      title="Page Not Found"
      hideNav={true}
      hideHeader={true}
      containerStyle="max-w-[50rem] flex flex-col mx-auto size-full"
    >
      <header className="flex justify-center z-50 w-full py-4 mt-12">
        <nav className="px-4 sm:px-6 lg:px-8" aria-label="Global">
          <Link
            className="flex items-center text-xl font-semibold sm:text-3xl dark:text-white"
            to="/"
            aria-label="ClientBase Branding"
          >
            <img src={Logo} className="h-12 w-12" />{" "}
            <span className="ml-2">ClientBase</span>
          </Link>
        </nav>
      </header>
      <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
        <h1 className="block text-7xl font-bold text-gray-800 sm:text-9xl dark:text-white">
          404
        </h1>
        <h1 className="block text-2xl font-bold text-white" />
        <p className="mt-3 text-gray-600 dark:text-gray-400">
          Oops, something went wrong. We could not find this page.
        </p>
        <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
          <Link
            className="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            to="/"
          >
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
            Back to dashboard
          </Link>
        </div>
      </div>
    </Page>
  );
}
