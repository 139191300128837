const Objects = {
    /**
     * helper function to dynamically access nested object fields 
     * where the parent field may be undefined or null
     */
    getField: (obj: any, field: string, nestedFields?: any) => {
        
        if (obj[field] != undefined && obj[field] != null) {

            if (nestedFields?.length > 0 && nestedFields[0] != undefined &&  nestedFields[0] != null) {
                let currentFieldValue = obj[field];

                nestedFields.forEach((nestedField: any) => {
                    if (currentFieldValue != undefined && currentFieldValue != null) {
                        currentFieldValue = currentFieldValue[nestedField];
                    }
                });

                return (currentFieldValue == undefined) ? null: currentFieldValue;
            } else {
                return obj[field];
            }
        }

        return null;
    }
};

export default Objects;