import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";

import API from "../helpers/api";
import Dates from "../helpers/dates";
import PageDetails, { FormField } from "./../components/pageDetails";

// TODO: Improve
const BREADCRUMB = {
  root: {
    title: "Clients",
    url: "/clients",
  },
  crumbs: [
    {
      isCurrent: true,
      title: "Manage Client",
      url: "#",
    },
  ],
};

export default function ClientDetails() {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState<string>("");
  const [loadingClientDetails, setLoadingClientDetails] =
    useState<boolean>(true);

  const [clientName, setClientName] = useState<string>("");
  const [clientEmail, setClientEmail] = useState<string>("");
  const [clientCell, setClientCell] = useState<string>("");
  const [clientNotes, setClientNotes] = useState<string>("");

  const [minDate, setMinDate] = useState<string>("");
  const [clientReminderDate, setClientReminderDate] = useState<string>("");

  const [clientTypeValue, setClientTypeValue] = useState<any>(null);
  const [clientTypeOptions, setClientTypeOptions] = useState<any>({
    list: [],
  });

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    if (state?.cachedDetails && state?.cachedDetails?.id === id) {
      setClientName(state.cachedDetails.name ?? "");
      setClientEmail(state.cachedDetails.email ?? "");
      setClientCell(state.cachedDetails.cell ?? "");
      setClientNotes(state.cachedDetails.notes ?? "");

      const tempTypeId = state.cachedDetails.type?.id;
      const tempTypeTitle = state.cachedDetails.type?.title;

      setClientTypeValue({ value: tempTypeId, label: tempTypeTitle });

      if (state.cachedDetails.follow_up) {
        setMinDate(Dates(state.cachedDetails.follow_up).format("YYYY-MM-DD"));
        setClientReminderDate(
          Dates(state.cachedDetails.follow_up).format("YYYY-MM-DD")
        );
      } else {
        setMinDate(Dates().format("YYYY-MM-DD"));
        setClientReminderDate("");
      }

      setLoadingClientDetails(false);
    } else if (id === "new") {
      setMinDate(Dates().format("YYYY-MM-DD"));
      setLoadingClientDetails(false);
    } else {
      setLoadingClientDetails(true);

      API.clients
        .get(id as string, signal)
        .then((res: any) => {
          setClientName(res.name ?? "");
          setClientEmail(res.email ?? "");
          setClientCell(res.cell ?? "");
          setClientNotes(res.notes ?? "");

          const tempTypeId = res.type.id;
          const tempTypeTitle = res.type.title;

          setClientTypeValue({ value: tempTypeId, label: tempTypeTitle });

          if (res.follow_up) {
            setMinDate(Dates(res.follow_up).format("YYYY-MM-DD"));
            setClientReminderDate(Dates(res.follow_up).format("YYYY-MM-DD"));
          } else {
            setMinDate(Dates().format("YYYY-MM-DD"));
            setClientReminderDate("");
          }

          setLoadingClientDetails(false);
        })
        .catch(() => {
          if (!signal.aborted) toast.error("Failed to load client details");
        });
    }

    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    if (id === "new") {
      setPageTitle("Add Client");
    } else {
      setPageTitle(clientName.trim().length !== 0 ? clientName : "Edit Client");
    }
  }, [clientName]);

  const [loadingClientTypes, setLoadingClientTypes] = useState<boolean>(false);
  const [clientTypeSearch, setClientTypeSearch] = useState<string>("");
  const [clientTypeSearchValue] = useDebounce(clientTypeSearch, 1000);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();

    fetchClientTypes(clientTypeSearchValue, controller);

    return () => {
      controller.abort();
    };
  }, [clientTypeSearchValue]);

  // TODO: Improve this code
  const fetchClientTypes = (searchTxt: string, controller: any) => {
    setLoadingClientTypes(true);

    API.clientTypes
      .list(searchTxt, controller.signal)
      .then((res: any) => {
        const tempClientTypes: {
          value: string;
          label: string;
          disabled?: boolean;
        }[] = [];

        const createType = {
          value: "new_" + searchTxt,
          label: `Create "${searchTxt}"`,
          disabled: false,
        };

        res.data.forEach((clientType: any) => {
          tempClientTypes.push({
            value: clientType.id,
            label: clientType.title,
          });

          if (clientType.title.toLowerCase() === searchTxt.toLowerCase()) {
            createType.disabled = true;
          }
        });

        if (searchTxt.trim().length > 0) {
          tempClientTypes.push(createType);
        }

        setClientTypeOptions({ list: tempClientTypes });
        setLoadingClientTypes(false);
      })
      .catch(() => {
        if (!controller.signal.aborted)
          toast.error("Failed to search client types");

        setLoadingClientTypes(false);
      });
  };

  // TODO: Improve this code
  const handleClientTypeValue = (value: any) => {
    if (value?.value.startsWith("new_")) {
      setLoadingClientTypes(true);

      value.label = value.value.split("_")[1];

      API.clientTypes
        .add({ title: value.label })
        .then((res: any) => {
          value.value = res.id;
          toast.success("Client type created successfully");
          setClientTypeValue(value);
          setLoadingClientTypes(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to create client type");
          setLoadingClientTypes(false);
        });
    }

    setClientTypeValue(value);
  };

  const onSaveChanges = () => {
    if (clientTypeValue === null) {
      toast.error("Client type is required");
      return;
    }

    setLoadingClientDetails(true);

    let follow_up = null;

    if (clientReminderDate) {
      follow_up = Dates(clientReminderDate + " 09:00")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    // TODO: Only send changed fields
    const clientDetails = {
      name: clientName,
      email: clientEmail,
      cell: clientCell,
      notes: clientNotes,
      follow_up: follow_up,
      type_id: clientTypeValue?.value,
    };

    if (id === "new") {
      API.clients
        .add(clientDetails)
        .then(() => {
          toast.success("Client added successfully");
          navigate("/clients");
        })
        .catch(() => {
          toast.error("Failed to add client");
          setLoadingClientDetails(false);
        });
    } else {
      API.clients
        .update(id as string, clientDetails)
        .then(() => {
          toast.success("Client updated successfully");
          navigate("/clients");
        })
        .catch(() => {
          toast.error("Failed to update client");
          setLoadingClientDetails(false);
        });
    }
  };

  return (
    <PageDetails
      title={pageTitle}
      loading={loadingClientDetails}
      onDiscard={() => navigate("/clients")}
      onSave={onSaveChanges}
      saveTitle={id === "new" ? "Add client" : "Save changes"}
      disableActions={loadingClientTypes}
      breadcrumb={BREADCRUMB}
    >
      <FormField
        id="client_name"
        title="Full name"
        type="text"
        value={clientName}
        onChange={(e: any) => setClientName(e.target.value)}
        required={true}
      />
      <FormField
        id="client_type"
        title="Type"
        type="searchable"
        value={clientTypeValue}
        onChange={handleClientTypeValue}
        required={true}
        selectConfig={{
          options: clientTypeOptions.list,
          loading: loadingClientTypes,
          onSearch: (e: any) => setClientTypeSearch(e.target.value),
        }}
      />
      <FormField
        id="client_email"
        title="Email address"
        type="email"
        value={clientEmail}
        onChange={(e: any) => setClientEmail(e.target.value)}
      />
      <FormField
        id="client_cell"
        title="Cellphone number"
        type="tel"
        value={clientCell}
        onChange={(e: any) => setClientCell(e.target.value)}
      />
      <FormField
        id="client_reminder"
        title="Follow up reminder"
        type="date"
        value={clientReminderDate}
        minValue={minDate}
        onChange={(e: any) => setClientReminderDate(e.target.value)}
        onFocus={(e: any) => (e.nativeEvent.target.defaultValue = "")}
      />
      <FormField
        id="client_notes"
        title="Notes"
        type="textarea"
        value={clientNotes}
        onChange={(e: any) => setClientNotes(e.target.value)}
      />
    </PageDetails>
  );
}
