import {
  HomeIcon,
  UsersIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ArrowRightStartOnRectangleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Logo from "./../assets/logo_icon.png";

const NavItem = ({
  href,
  title,
  icon,
  type,
  onClick,
}: {
  href?: string;
  title: string;
  icon: React.ReactNode;
  type?: string;
  onClick?: any;
}) => {
  return (
    <li key={href}>
      {type === "button" ? (
        <button
          onClick={onClick}
          type="button"
          className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        >
          {icon} {title}
        </button>
      ) : (
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-100 text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-900 dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              : "w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          }
          to={href ?? "#"}
        >
          {icon}
          {title}
        </NavLink>
      )}
    </li>
  );
};

export default function NavBar() {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // TODO: Improve - refresh Preline UI overlay event listeners
      document.getElementById("main-sidebar-backdrop")?.remove();
      document.getElementsByTagName("body")[0].setAttribute("style", "");
    };
  }, []);

  const onLogout = () => {
    navigate("/login", {
      state: { from: "/", action: "logout" },
    });
  };

  return (
    <>
      {/* Sidebar Toggle */}
      <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-gray-800 dark:border-gray-700">
        <div className="flex items-center py-4">
          {/* Navigation Toggle */}
          <button
            type="button"
            className="text-gray-500 hover:text-gray-600"
            data-hs-overlay="#main-sidebar"
            aria-controls="main-sidebar"
            aria-label="Toggle navigation"
          >
            <span className="sr-only">Toggle Navigation</span>
            <svg
              className="size-5"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          {/* End Navigation Toggle */}

          {/* Breadcrumb */}
          <div className="ms-3 flex items-center whitespace-nowrap">
            <NavLink
              to="/"
              aria-label="ClientBase Branding"
              className="flex items-center text-lg text-gray-800 dark:text-gray-400 font-semibold"
            >
              ClientBase
            </NavLink>
          </div>
          {/* End Breadcrumb */}
        </div>
      </div>
      {/* End Sidebar Toggle */}

      <div
        id="main-sidebar"
        className="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 z-[60] w-64 bg-white border-e border-gray-200 pt-7 pb-4 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 dark:bg-gray-800 dark:border-gray-700"
      >
        <div className="px-6">
          <NavLink
            className="flex items-center text-xl font-semibold dark:text-white"
            to="/"
            aria-label="ClientBase Branding"
          >
            <img src={Logo} className="h-8 w-8" />{" "}
            <span className="ml-2">ClientBase</span>
          </NavLink>
        </div>
        <nav
          className="hs-accordion-group p-6 w-full flex justify-between h-[95%] flex-col"
          data-hs-accordion-always-open=""
        >
          <ul className="space-y-1.5">
            <NavItem
              href="/"
              title="Dashboard"
              icon={<HomeIcon className="h-6 w-6" />}
            />
            <NavItem
              href="/clients"
              title="Clients"
              icon={<UsersIcon className="h-6 w-6" />}
            />
            <NavItem
              href="/properties"
              title="Properties"
              icon={<BuildingOffice2Icon className="h-6 w-6" />}
            />
            <NavItem
              href="/viewings"
              title="Viewings"
              icon={<CalendarDaysIcon className="h-6 w-6" />}
            />
          </ul>
          <ul className="space-y-1.5">
            <NavItem
              href="/settings"
              title={"Settings"}
              icon={<UserCircleIcon className="h-6 w-6" />}
            />
            <NavItem
              type="button"
              title="Log out"
              icon={<ArrowRightStartOnRectangleIcon className="h-6 w-6" />}
              onClick={onLogout}
            />
          </ul>
        </nav>
      </div>
    </>
  );
}
