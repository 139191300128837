import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { CSSProperties, useEffect, useState } from "react";

import Objects from "../helpers/objects";

const TableHeading = ({
  title,
  styles,
}: {
  title: string;
  styles?: CSSProperties;
}) => {
  return (
    <th
      scope="col"
      className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
      style={styles}
    >
      {title}
    </th>
  );
};

const TableRow = ({
  data,
  headings,
  options,
  onOption,
  onDelete,
}: {
  data: any;
  headings: Array<any>;
  options: Array<any>;
  onOption: Function;
  onDelete?: Function;
}) => {
  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 min-w-fi">
      {headings.map((cell) => (
        <td
          key={`cell_${cell.id}_${data.id}`}
          //className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200"
          //white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;
          className={`
            px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 text-ellipsis overflow-hidden 
            ${cell.width === "max" ? " min-w-52 max-w-60 " : ""}
            ${cell.width === "fit" ? " min-w-fit max-w-fit " : ""}
          `}
          //className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 text-ellipsis overflow-hidden min-w-52 max-w-60"
        >
          {cell.type === "badge" ? (
            <TableRowBadge cell={cell} data={data} />
          ) : cell.type === "link" ? (
            <TableRowLink cell={cell} data={data} />
          ) : (
            <TableRowText cell={cell} data={data} />
          )}
        </td>
      ))}
      <TableRowOptions
        id={data.id}
        options={options}
        onOption={onOption}
        onDelete={onDelete}
        data={data}
      />
    </tr>
  );
};

const TableRowText = ({ cell, data }: { cell: any; data: any }) => {
  const [cellValue, setCellValue] = useState<any>(null);

  useEffect(() => {
    setCellValue(
      Objects.getField(data, cell.id, [cell.nested]) ?? cell.default
    );
  }, [data]);

  return cellValue ?? "";
};

const TableRowBadge = ({ cell, data }: { cell: any; data: any }) => {
  const [cellValue, setCellValue] = useState<any>(null);

  useEffect(() => {
    setCellValue(
      Objects.getField(data, cell.id, [cell.nested]) ?? cell.default
    );
  }, [data]);

  if (cellValue) {
    return (
      <span
        className={
          "inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border " +
          (cell.badge_colour ??
            "border-blue-600 text-blue-600 dark:text-blue-500")
        }
      >
        {cellValue}
      </span>
    );
  } else {
    return "";
  }
};

const TableRowLink = ({ cell, data }: { cell: any; data: any }) => {
  const [cellValue, setCellValue] = useState<any>(null);

  useEffect(() => {
    setCellValue(Objects.getField(data, cell.id, [cell.nested]));
  }, [data]);

  if (cellValue) {
    return (
      <Link
        className="block"
        to={
          cell.linkConfig.baseURL +
          (cell.linkConfig.nested
            ? data[cell.linkConfig.id][cell.linkConfig.nested]
            : data[cell.linkConfig.id])
        }
        state={
          cell.linkConfig.cache && data[cell.linkConfig.cache]
            ? { cachedDetails: data[cell.linkConfig.cache] }
            : {}
        }
      >
        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-500">
          {cellValue}
        </span>
      </Link>
    );
  } else {
    return cell.default ?? "";
  }
};

const TableRowOptions = ({
  id,
  options,
  onOption,
  onDelete,
  data,
}: {
  id: string;
  options: Array<any>;
  onOption: Function;
  onDelete?: Function;
  data: any;
}) => {
  return (
    <td className="size-px whitespace-nowrap">
      <div className="px-6 py-1.5">
        <div className="hs-dropdown relative inline-block [--placement:bottom-right]">
          <button
            id={`table_dropdown_${id}`}
            type="button"
            className="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-lg text-gray-700 align-middle disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
          >
            <EllipsisVerticalIcon className="h-5 w-5" />
          </button>

          <div
            className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-40 z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
            aria-labelledby={`table_dropdown_${id}`}
          >
            <div className="py-2 first:pt-0 last:pb-0">
              {options.map((option) => (
                <button
                  key={`dropdown_${id}_${option.id}`}
                  className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  onClick={() => onOption(option.id, data)}
                >
                  {option.title}
                </button>
              ))}
            </div>
            {onDelete && (
              <div className="py-2 first:pt-0 last:pb-0">
                <button
                  key={`dropdown_delete_${id}`}
                  className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                  onClick={() => onDelete(data)}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </td>
  );
};

const TableFooter = ({
  resultCount,
  pagination,
  handlePagination,
  searchTerm,
}: {
  resultCount: number;
  pagination?: any;
  handlePagination?: any;
  searchTerm?: string;
}) => {
  const [prev, setPrev] = useState<any>(null);
  const [next, setNext] = useState<any>(null);

  useEffect(() => {
    if (pagination) {
      const hasPrev = pagination.current != 1;

      setPrev(hasPrev ? pagination.current - 1 : null);
      setNext(pagination.next);
    }
  }, [pagination]);

  const onPrevPage = () => {
    handlePagination(prev);
  };

  const onNextPage = () => {
    handlePagination(next);
  };

  // TODO: Add clear search button in footer next to search term
  // TODO: Clear search terms in the query parameters as well

  return (
    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
      <div>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          <span className="font-semibold text-gray-800 dark:text-gray-200">
            {resultCount}
          </span>{" "}
          {resultCount === 1 ? "result" : "results"}
          {searchTerm && (
            <>
              {" for "}
              <span className="italic font-semibold">{searchTerm}</span>
            </>
          )}
        </p>
      </div>
      <div>
        <div className="inline-flex gap-x-2">
          <button
            onClick={onPrevPage}
            disabled={!prev}
            type="button"
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
            Prev
          </button>
          <button
            onClick={onNextPage}
            disabled={!next}
            type="button"
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            Next
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const TableEmptyState = ({
  icon,
  title,
  desc,
  actionTitle,
  onAction,
}: {
  icon?: React.ReactNode;
  title?: string;
  desc?: string;
  actionTitle?: string;
  onAction?: Function;
}) => {
  return (
    <div className="max-w-sm w-full min-h-[400px] flex flex-col justify-center mx-auto px-6 py-4">
      <div className="w-full flex justify-center items-center">
        <div className="p-4 flex justify-center items-center size-[100px] bg-gray-100 rounded-lg dark:bg-gray-800">
          {icon}
        </div>
      </div>
      <h2 className="mt-5 font-semibold text-gray-800 dark:text-white flex text-center justify-center">
        {title ?? "No results found"}
      </h2>
      {desc && (
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 flex text-center">
          {desc}
        </p>
      )}
      {actionTitle && (
        <div className="mt-5 grid sm:flex gap-2 justify-center">
          <button
            onClick={() => onAction && onAction()}
            type="button"
            className="py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14" />
              <path d="M12 5v14" />
            </svg>
            {actionTitle}
          </button>
        </div>
      )}
    </div>
  );
};

const TableEmptySearchState = ({
  searchTerm,
  onClearSearch,
}: {
  searchTerm?: string;
  onClearSearch?: Function;
}) => {
  return (
    <div className="max-w-sm w-full min-h-[400px] flex flex-col justify-center mx-auto px-6 py-4">
      <div className="w-full flex justify-center items-center">
        <h2 className="mt-5 font-semibold text-gray-800 dark:text-white flex text-center justify-center">
          <span>
            No results found for "<span className="italic">{searchTerm}</span>"
          </span>
        </h2>
      </div>
      {onClearSearch && (
        <div className="mt-5 grid sm:flex gap-2 justify-center">
          <button
            onClick={() => onClearSearch()}
            type="button"
            className="py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            Clear Search
          </button>
        </div>
      )}
    </div>
  );
};

export default function TableView({
  refreshKey,
  headings,
  loading,
  data,
  options,
  onOption,
  onDelete,
  emptyStateIcon,
  emptyStateTitle,
  emptyStateDesc,
  emptyStateActionTitle,
  emptyStateOnAction,
  pagination,
  handlePagination,
  searchTerm,
  onClearSearch,
}: {
  refreshKey?: any;
  headings: Array<any>;
  loading: Boolean;
  data: Array<any>;
  options: Array<any>;
  onOption: Function;
  onDelete?: Function;
  emptyStateIcon?: React.ReactNode;
  emptyStateTitle?: string;
  emptyStateDesc?: string;
  emptyStateActionTitle?: string;
  emptyStateOnAction?: Function;
  pagination?: any;
  handlePagination?: Function;
  searchTerm?: string;
  onClearSearch?: Function;
}) {
  useEffect(() => {
    // TODO: Improve - refresh Preline UI dropdown event listeners
    window.HSStaticMethods.autoInit();
  }, [data]);

  return (
    <>
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
          <div className="px-2.5 py-4 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              {loading && (
                <div className="text-center mt-20 mb-20 pt-20 pb-20">
                  <div
                    className=" animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {!loading && data.length === 0 && (
                <>
                  {searchTerm ? (
                    <TableEmptySearchState
                      searchTerm={searchTerm}
                      onClearSearch={onClearSearch}
                    />
                  ) : (
                    <TableEmptyState
                      icon={emptyStateIcon}
                      title={emptyStateTitle}
                      desc={emptyStateDesc}
                      actionTitle={emptyStateActionTitle}
                      onAction={emptyStateOnAction}
                    />
                  )}
                </>
              )}
              {!loading && data.length !== 0 && (
                <>
                  <table
                    key={refreshKey ?? 0}
                    className="min-w-full divide-y divide-gray-200 dark:divide-gray-700"
                  >
                    <thead>
                      <tr>
                        {headings.map((heading) => (
                          <TableHeading
                            key={heading.id}
                            title={heading.title}
                            styles={heading.styles}
                          />
                        ))}
                        <th
                          scope="col"
                          className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                        />
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                      {data.map((dataRow) => (
                        <TableRow
                          key={dataRow.id}
                          data={dataRow}
                          headings={headings}
                          options={options}
                          onOption={onOption}
                          onDelete={onDelete}
                        />
                      ))}
                    </tbody>
                  </table>
                  <TableFooter
                    resultCount={data.length}
                    pagination={pagination}
                    handlePagination={handlePagination}
                    searchTerm={searchTerm}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
