import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import UTC from "dayjs/plugin/utc";
import Timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en-gb";

dayjs.locale("en-gb"); // TODO: Load from user prefs else use default locale en
dayjs.extend(LocalizedFormat);
dayjs.extend(UTC);
dayjs.extend(Timezone);

const currentTimezone = dayjs.tz.guess();
const currentTimezoneGMT = "GMT" + dayjs().format('Z');

export {currentTimezone, currentTimezoneGMT};

export default function Dates(date?: string | number | dayjs.Dayjs | Date | null | undefined){
    return dayjs(date);
};