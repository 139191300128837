import Page from "./../components/page";
import Select from "react-tailwindcss-select";

export const FormField = ({
  id,
  title,
  type,
  placeholder,
  value,
  onChange,
  onFocus,
  minValue,
  required,
  selectConfig,
  disabled,
  readOnly,
  footer,
}: {
  id: string;
  title: string;
  type: string;
  placeholder?: string;
  value?: any;
  onChange?: Function;
  onFocus?: Function;
  minValue?: any;
  required?: boolean;
  selectConfig?: any;
  disabled?: boolean;
  readOnly?: boolean;
  footer?: any;
}) => {
  return (
    <>
      <div className="sm:col-span-3">
        <label
          htmlFor={id}
          className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-gray-200"
        >
          {`${title} ${required ? "*" : ""}`}
        </label>
      </div>

      <div className="sm:col-span-9">
        {type === "textarea" ? (
          <textarea
            id={id}
            className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
            rows={6}
            placeholder={placeholder}
            defaultValue={value}
            onChange={(e) => onChange && onChange(e)}
            onFocus={(e) => onFocus && onFocus(e)}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : (
          <>
            {type === "searchable" ? (
              <Select
                primaryColor="blue"
                value={value}
                onChange={(value) => onChange && onChange(value)}
                options={selectConfig?.options}
                loading={selectConfig?.loading}
                isSearchable={true}
                onSearchInputChange={selectConfig?.onSearch}
                isDisabled={disabled || readOnly}
                classNames={{
                  menuButton: ({ isDisabled }: any) =>
                    `flex text-sm text-gray-900 border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                      isDisabled
                        ? "bg-gray-200"
                        : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                    }`,
                  listItem: ({ isSelected }: any) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? "text-white bg-blue-500"
                        : "text-gray-600 hover:bg-blue-100 hover:text-blue-500"
                    }`,
                }}
              />
            ) : (
              <>
                <input
                  id={id}
                  type={type}
                  className="py-2 px-3 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder={placeholder}
                  value={value}
                  onChange={(e) => onChange && onChange(e)}
                  onFocus={(e) => onFocus && onFocus(e)}
                  min={minValue}
                  required={required}
                  disabled={disabled}
                  readOnly={readOnly}
                />
              </>
            )}
          </>
        )}
        {footer && <div>{footer}</div>}
      </div>
    </>
  );
};

export default function PageDetails({
  title,
  description,
  children,
  loading,
  onDiscard,
  discardTitle,
  onSave,
  saveTitle,
  disableActions,
  breadcrumb,
}: {
  title: string;
  description?: any;
  children?: any;
  loading?: boolean;
  onDiscard?: Function;
  discardTitle?: string;
  onSave?: Function;
  saveTitle?: string;
  disableActions?: boolean;
  breadcrumb?: any;
}) {
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    onSave && onSave();
  };

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <div className="mx-auto">
        <div className="bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-slate-900">
          {loading ? (
            <div className="text-center mt-20 mb-20 pt-20 pb-20">
              <div
                className=" animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <form onSubmit={(e) => handleFormSubmit(e)}>
              <p className="italic text-sm text-gray-600 dark:text-neutral-400 mb-6">
                Fields marked with * are required.
                <br />
                {description}
              </p>
              <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                {children}
              </div>
              <div className="mt-5 flex justify-end gap-x-2">
                {onDiscard && (
                  <button
                    disabled={disableActions}
                    onClick={() => onDiscard()}
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
                  >
                    {discardTitle ?? "Cancel"}
                  </button>
                )}
                {onSave && (
                  <button
                    disabled={disableActions}
                    type="submit"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    {saveTitle ?? "Save changes"}
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </Page>
  );
}
