import "./App.css";

import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";

import { Toaster } from "react-hot-toast";

import { useEffect, useState } from "react";

import "preline/preline";
import { IStaticMethods } from "preline";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

import { AuthProvider, useAuth } from "./hooks/auth";
import API from "./helpers/api";

import Splash from "./components/splash";
import ProtectedRoute from "./components/protectedRoute";
import Dashboard from "./pages/dashboard";
import Clients from "./pages/clients";
import ClientDetails from "./pages/clientDetails";
import Properties from "./pages/properties";
import PropertyDetails from "./pages/propertyDetails";
import Viewings from "./pages/viewings";
import ViewingDetails from "./pages/viewingDetails";
import PageNotFound from "./pages/pageNotFound";
import Login from "./pages/login";
import Settings from "./pages/settings";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

const Redirect = ({ to }: { to: string }) => {
  const { id } = useParams();

  return <Navigate to={`/${to}/${id}`} replace />;
};

function Pages() {
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const { onLogin, onLogout } = useAuth();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    API.auth
      .getUser(signal)
      .then((data: any) => {
        onLogin(data);
        setLoadingAuth(false);
      })
      .catch(() => {
        if (!signal.aborted) {
          onLogout();
          setLoadingAuth(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Splash loading={loadingAuth}>
      <Routes>
        {/* Dashboard */}
        <Route
          path="/"
          index
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {/* Clients */}
        <Route
          path="clients"
          element={
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          }
        />
        <Route
          path="clients/:id"
          element={
            <ProtectedRoute>
              <ClientDetails />
            </ProtectedRoute>
          }
        />
        <Route path="c/:id" element={<Redirect to="clients" />} />

        {/* Properties */}
        <Route
          path="properties"
          element={
            <ProtectedRoute>
              <Properties />
            </ProtectedRoute>
          }
        />
        <Route
          path="properties/:id"
          element={
            <ProtectedRoute>
              <PropertyDetails />
            </ProtectedRoute>
          }
        />
        <Route path="p/:id" element={<Redirect to="properties" />} />

        {/* Viewings */}
        <Route
          path="viewings"
          element={
            <ProtectedRoute>
              <Viewings />
            </ProtectedRoute>
          }
        />
        <Route
          path="viewings/:id"
          element={
            <ProtectedRoute>
              <ViewingDetails />
            </ProtectedRoute>
          }
        />
        <Route path="v/:id" element={<Redirect to="viewings" />} />

        {/* Settings */}
        <Route
          path="settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />

        {/* Auth */}
        <Route path="login" element={<Login />} />

        {/* 404 */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Splash>
  );
}

function Root() {
  const location = useLocation();

  useEffect(() => {
    // TODO: Improve - refresh Preline UI event listeners
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <>
      {/* TODO: Add dark theme support to Toast messages */}
      <Toaster
        position="bottom-right"
        containerStyle={{
          top: 40,
          left: 40,
          bottom: 40,
          right: 40,
        }}
        toastOptions={{
          duration: 5000,
        }}
      />

      <AuthProvider>
        <Pages />
      </AuthProvider>
    </>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
