import { useState, useEffect } from "react";
import Logo from "./../assets/logo_icon.png";

export default function Splash({
  loading,
  children,
}: {
  loading: boolean;
  children: any;
}) {
  const [showSplash, setShowSplash] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setShowSplash(true);
    }, 1200);
  }, []);

  if (loading) {
    return (
      <div
        className={`${
          showSplash ? "flex" : "hidden"
        } w-full h-full items-center justify-center`}
      >
        <div className="flex items-center animate-pulse">
          <img src={Logo} className="h-12 w-12 mr-2" alt="ClientBase Logo" />
          <p className="text-2xl font-semibold dark:text-white">ClientBase</p>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
}
