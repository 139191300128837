import { HSOverlay } from "preline/preline";
import { useEffect } from "react";

import useEscapeKey from "../hooks/escapeKey";

export default function Modal({
  children,
  show,
  id,
  title,
  hideCancel,
  cancelTxt,
  confirmTxt,
  confirmStyle,
  onConfirm,
  onClose,
  loading,
}: {
  children: any;
  show: boolean;
  id: string;
  title: string;
  hideCancel?: boolean;
  cancelTxt?: string;
  confirmTxt?: string;
  confirmStyle?: string;
  onConfirm?: Function;
  onClose: Function;
  loading?: boolean;
}) {
  useEffect(() => {
    // TODO: Improve - refresh Preline UI dropdown event listeners
    window.HSStaticMethods.autoInit();

    if (show) {
      HSOverlay.open(`#component_modal_${id}` as any);
    } else {
      HSOverlay.close(`#component_modal_${id}` as any);
    }
  }, [show]);

  // TODO: Find way to stop escape key closing modal during loading state
  useEscapeKey(onClose);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    onConfirm ? onConfirm() : onClose();
  };

  return (
    <div
      id={`component_modal_${id}`}
      className="hs-overlay size-full fixed top-0 start-0 z-[80] hidden overflow-x-hidden overflow-y-auto [--overlay-backdrop:static]"
      data-hs-overlay-keyboard={true}
    >
      <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
        <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
          <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
            <h3 className="font-bold text-gray-800 dark:text-white">{title}</h3>
            <button
              disabled={loading}
              onClick={() => onClose()}
              type="button"
              className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              data-hs-overlay={`#component_modal_${id}`}
            >
              <span className="sr-only">Close</span>
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="p-4 overflow-y-auto">{children}</div>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
              {!hideCancel && (
                <button
                  disabled={loading}
                  onClick={() => onClose()}
                  type="button"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  data-hs-overlay={`#component_modal_${id}`}
                >
                  {cancelTxt ?? "Cancel"}
                </button>
              )}
              <button
                disabled={loading}
                type="submit"
                className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ${
                  confirmStyle ? confirmStyle : ""
                }`}
              >
                {confirmTxt ?? "Confirm"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
