import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  useEffect,
  useState,
  Fragment,
} from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";

import NavBar from "./navBar";
import Modal from "./modal";
import UpdateBanner from "./updateBanner";

const FiltersModal = ({
  show,
  onClose,
  onSetFilters,
  filter,
  currentFilter,
  sort,
  currentSort,
  searchHint,
  currentSearch,
}: {
  show: boolean;
  onClose: Function;
  onSetFilters: Function;
  filter: any;
  currentFilter: any;
  sort: any;
  currentSort: any;
  searchHint?: string;
  currentSearch?: string;
}) => {
  const [sortId, setSortId] = useState<any>(currentSort);
  const [filterId, setFilterId] = useState<any>(currentFilter);
  const [searchTerms, setSearchTerms] = useState<string>(currentSearch ?? "");

  useEffect(() => {
    if (currentSearch != searchTerms) {
      setSearchTerms(currentSearch ?? "");
    }
  }, [currentSearch]);

  useEffect(() => {
    setSortId(currentSort);
    setFilterId(currentFilter);
  }, [currentSort, currentFilter]);

  const handleFilters = () => {
    onSetFilters(sortId, filterId, searchTerms.trim());
  };

  return (
    <Modal
      id="set_filters"
      title="Search and Filters"
      confirmTxt="Apply"
      show={show}
      onClose={onClose}
      onConfirm={handleFilters}
    >
      <div className="mb-5">
        <label
          htmlFor="filters_search_terms"
          className="block text-sm font-medium mb-2 dark:text-white"
        >
          Search
        </label>
        <input
          type="text"
          id="filters_search_terms"
          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
          placeholder={searchHint ?? "Search"}
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
        />
      </div>
      {sort && (
        <div className="mb-5">
          <label
            htmlFor="filters_sort_by"
            className="block text-sm font-medium mb-2 dark:text-white"
          >
            Sort By
          </label>
          <select
            id="filters_sort_by"
            className="py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
            value={sortId}
            onChange={(e) => setSortId(e.target.value)}
          >
            {sort.map((sortItem: any) => (
              <option key={sortItem.id} value={sortItem.id}>
                {sortItem.title}
              </option>
            ))}
          </select>
        </div>
      )}
      {filter && (
        <div className="mb-5">
          <label
            htmlFor="filters_filter_by"
            className="block text-sm font-medium mb-2 dark:text-white"
          >
            Filter By
          </label>
          <select
            id="filters_filter_by"
            className="py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
            value={filterId}
            onChange={(e) => setFilterId(e.target.value)}
          >
            {filter.map((filterItem: any) => (
              <option key={filterItem.id} value={filterItem.id}>
                {filterItem.title}
              </option>
            ))}
          </select>
        </div>
      )}
    </Modal>
  );
};

export default function Page({
  children,
  title,
  hideNav,
  hideHeader,
  containerStyle,
  sort,
  handleSort,
  currentSort,
  filter,
  handleFilter,
  currentFilter,
  currentSearch,
  handleSearch,
  createTxt,
  onCreate,
  breadcrumb,
  searchHint,
}: {
  children:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null;
  title: String;
  hideNav?: Boolean;
  hideHeader?: Boolean;
  containerStyle?: any;
  sort?: any;
  handleSort?: any;
  currentSort?: any;
  filter?: any;
  handleFilter?: any;
  currentFilter?: any;
  currentSearch?: string;
  handleSearch?: Function;
  createTxt?: string;
  onCreate?: Function;
  breadcrumb?: any;
  searchHint?: string;
}) {
  useEffect(() => {
    document.title = `${title} - ClientBase`;
  }, [title]);

  const [queryParams, setQueryParams] = useSearchParams();

  useEffect(() => {
    const sort_id = queryParams.get("sort") ?? currentSort;
    const filter_id = queryParams.get("filter") ?? currentFilter;
    const search_terms = queryParams.get("query") ?? currentSearch ?? "";

    handleFilters(sort_id, filter_id, search_terms, true);
  }, []);

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  const handleFilters = (
    sortId: any,
    filterId: any,
    searchTerms: string,
    firstPageLoad?: boolean
  ) => {
    setShowFiltersModal(false);

    // find the sort value using the id
    const sortValue = sort
      ? sort.find((sortItem: any) => {
          return sortItem.id === sortId || sortItem?.alt_id === sortId;
        })
      : null;

    // find the filter value using the id
    const filterValue = filter
      ? filter.find((filterItem: any) => {
          return filterItem.id === filterId || filterItem?.alt_id === filterId;
        })
      : null;

    // update react state
    sort && sortValue && handleSort(sortValue);
    filter && filterValue && handleFilter(filterValue);
    handleSearch && handleSearch(searchTerms);

    // first get the initials queries
    let newParams = Object.fromEntries(queryParams.entries());

    // update params to match react state
    newParams["sort"] = sortValue?.id ?? null;
    newParams["filter"] = filterValue?.id ?? null;
    newParams["query"] = searchTerms ?? null;

    // if first page load then preserve set parameters
    // example: /page?test=1  =>  /page?test=1
    //          /page         =>  /page
    if (firstPageLoad) {
      newParams["sort"] = queryParams.get("sort") ? newParams["sort"] : "";
      newParams["filter"] = queryParams.get("filter")
        ? newParams["filter"]
        : "";
      newParams["query"] = queryParams.get("query") ? newParams["query"] : "";
    }

    // remove null params
    newParams = Object.fromEntries(
      Object.entries(newParams).filter(([_, v]) => v != null && v != "")
    );

    // set new query params
    setQueryParams(newParams);
  };

  return (
    <>
      <FiltersModal
        show={showFiltersModal}
        onClose={() => setShowFiltersModal(false)}
        onSetFilters={handleFilters}
        filter={filter}
        sort={sort}
        currentFilter={currentFilter}
        currentSort={currentSort}
        currentSearch={currentSearch}
        searchHint={searchHint}
      />
      {/* Banner to prompt for PWA update */}
      <UpdateBanner padLeft={!hideNav} />
      {!hideNav && <NavBar />}
      <div
        className={
          containerStyle ??
          "w-full lg:pt-10 pt-6 px-4 sm:px-6 md:px-8 lg:ps-72 pb-20"
        }
      >
        {breadcrumb && (
          <ol className="flex items-center whitespace-nowrap">
            <li className="inline-flex items-center">
              <NavLink
                className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-neutral-500 dark:hover:text-blue-500 dark:focus:text-blue-500"
                to={breadcrumb.root.url}
              >
                {breadcrumb.root.title}
              </NavLink>
              <svg
                className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6"></path>
              </svg>
            </li>
            {breadcrumb.crumbs.map((crumb: any) => (
              <Fragment key={crumb.url}>
                {crumb.isCurrent ? (
                  <li
                    className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-neutral-200"
                    aria-current="page"
                  >
                    {crumb.title}
                  </li>
                ) : (
                  <li className="inline-flex items-center">
                    <NavLink
                      className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-neutral-500 dark:hover:text-blue-500 dark:focus:text-blue-500"
                      to={crumb.url}
                    >
                      {crumb.title}
                      <svg
                        className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m9 18 6-6-6-6"></path>
                      </svg>
                    </NavLink>
                  </li>
                )}
              </Fragment>
            ))}
          </ol>
        )}
        {!hideHeader && (
          <header className="mb-8 flex justify-between flex-wrap">
            <h1 className="block text-2xl font-semibold text-gray-800 sm:text-3xl dark:text-white">
              {title}
            </h1>
            <div className="flex">
              {(filter || sort) && (
                <button
                  onClick={() => setShowFiltersModal(!showFiltersModal)}
                  className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                >
                  <AdjustmentsHorizontalIcon className="h-5 w-5" />
                  Filters
                </button>
              )}
              {onCreate && (
                <button
                  onClick={() => onCreate()}
                  className="ml-3 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <svg
                    className="flex-shrink-0 size-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  {createTxt ?? "New"}
                </button>
              )}
            </div>
          </header>
        )}
        {children}
      </div>
    </>
  );
}
