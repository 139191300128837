import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import Page from "./../components/page";
import API from "../helpers/api";
import Dates from "../helpers/dates";

const Section = ({
  loading,
  emptyDesc,
  title,
  data,
  dataTitle,
  dataDesc,
  dataBaseURL,
  loadMore,
  loadMoreURL,
}: {
  loading?: boolean;
  emptyDesc?: string;
  title: string;
  data: Array<any>;
  dataTitle: string;
  dataDesc: string;
  dataBaseURL: string;
  loadMore?: boolean;
  loadMoreURL?: string;
}) => {
  return (
    <div className="max-w-[85rem] pb-10 lg:pb-8 mx-auto">
      <h2 className="block mb-3 text-xl font-semibold text-gray-800 dark:text-white">
        {title}
      </h2>
      {!loading && data.length === 0 ? (
        <div className="w-full h-32 flex items-center justify-center">
          <p className="text-lg text-gray-700">
            {emptyDesc ?? "You're all caught up!"}
          </p>
        </div>
      ) : (
        <>
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-3 sm:gap-6">
            {loading ? (
              <>
                {Array(3)
                  .fill(true)
                  .map((_, i) => (
                    <div
                      key={i}
                      className="group flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-800"
                    >
                      <div className="flex animate-pulse">
                        <div className="my-2 w-full p-4 md:p-5">
                          <p className="h-4 bg-gray-200 rounded-full dark:bg-neutral-700" />
                          <p
                            className="h-4 bg-gray-200 rounded-full dark:bg-neutral-700 mt-4"
                            style={{ width: "40%" }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {data.map((card: any) => (
                  <Link
                    className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800"
                    to={`${dataBaseURL}/${card.id}`}
                    key={card.id}
                    state={{ cachedDetails: card }}
                  >
                    <div className="p-4 md:p-5">
                      <div className="flex justify-between items-center">
                        <div>
                          <h3 className="mb-3 group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                            {card[dataTitle]}
                          </h3>
                          <p className="text-sm text-gray-500 dark:text-neutral-500">
                            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-blue-600 text-blue-600 dark:text-blue-500">
                              {card[dataDesc]}
                            </span>
                          </p>
                        </div>
                        <div className="ps-3">
                          <svg
                            className="flex-shrink-0 size-5 text-gray-800 dark:text-neutral-200"
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </div>
          {!loading && loadMore && (
            <div className="text-center mt-6 mb-2">
              <Link
                className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 font-medium dark:text-blue-500"
                to={loadMoreURL ?? "#"}
              >
                View All
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default function Dashboard() {
  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  const [clients, setClients] = useState<any>([]);
  const [moreClients, setMoreClients] = useState<boolean>(false);

  const [loadingViewings, setLoadingViewings] = useState<boolean>(true);
  const [viewings, setViewings] = useState<any>([]);
  const [moreViewings, setMoreViewings] = useState<boolean>(false);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    setLoadingClients(true);
    setLoadingViewings(true);

    API.dashboard
      .listFollowUps(signal)
      .then((res: any) => {
        res.data.forEach((data: any) => {
          data.date_formatted = Dates(data.follow_up).format("LL");
        });

        setMoreClients(res.pagination?.next ? true : false);
        setClients(res.data);
        setLoadingClients(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load client reminders");
      });

    API.dashboard
      .listUpcomingViewings(signal)
      .then((res: any) => {
        res.data.forEach((data: any) => {
          data.date_formatted =
            Dates(data.starts_at).format("LL") +
            " - " +
            Dates(data.starts_at).local().format("HH:mm");
        });

        setMoreViewings(res.pagination?.next ? true : false);
        setViewings(res.data);
        setLoadingViewings(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load viewing reminders");
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Page title="Dashboard">
      <Section
        loading={loadingClients}
        title="Client Reminders"
        data={clients}
        dataTitle="name"
        dataDesc="date_formatted"
        dataBaseURL="/clients"
        loadMore={moreClients}
        loadMoreURL="/clients?sort=follow_up"
      />
      <Section
        loading={loadingViewings}
        title="Upcoming Viewings"
        data={viewings}
        dataTitle="title"
        dataDesc="date_formatted"
        dataBaseURL="/viewings"
        loadMore={moreViewings}
        loadMoreURL="/clients?filter=upcoming"
      />
    </Page>
  );
}
