import { createContext, useContext, useMemo, useState } from "react";
//import { useLocalStorage } from "./localStorage";

const AuthContext = createContext<any>({} as any);

export const AuthProvider = ({ children }: { children: any }) => {
  //const [user, setUser] = useLocalStorage("user", null);
  const [user, setUser] = useState<any>(null);

  // call this function when you want to authenticate the user
  const onLogin = async (data: any) => {
    setUser(data);
  };

  // call this function to sign out logged in user
  const onLogout = () => {
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      onLogin,
      onLogout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
