import { useRegisterSW } from "virtual:pwa-register/react";

export default function UpdateBanner({ padLeft }: { padLeft?: boolean }) {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered() {
      //console.log("SW Registered: " + r);
    },
    onRegisterError() {
      //console.log("SW registration error", error);
    },
  });

  return (
    <div
      id="pwa-update-required-banner"
      className="hs-removing:-translate-y-full bg-blue-600"
      style={{
        display: needRefresh ? "block" : "none",
        visibility: needRefresh ? "visible" : "hidden",
      }}
      aria-hidden={!needRefresh}
    >
      <div
        className={`max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 mx-auto ${
          padLeft ? "lg:pl-[18rem]" : ""
        }`}
      >
        <div className="flex">
          <p className="text-white">
            A new version is available.
            <button
              type="button"
              className="ml-2 decoration-2 underline font-medium hover:text-white/80"
              onClick={() => needRefresh && updateServiceWorker(true)}
            >
              Update Now
            </button>
          </p>

          <div className="ps-3 ms-auto">
            <button
              type="button"
              className="inline-flex rounded-lg p-1.5 text-white/80 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white"
              onClick={() => setNeedRefresh(false)}
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
