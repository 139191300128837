import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  ClipboardIcon,
  CheckIcon,
  CalendarDaysIcon,
  ArrowTopRightOnSquareIcon as LinkIcon,
} from "@heroicons/react/24/outline";

import API from "../helpers/api";
import PageDetails, { FormField } from "./../components/pageDetails";
import { useAuth } from "../hooks/auth";

export default function Settings() {
  const { user } = useAuth();

  const [userName, setUserName] = useState(user?.name ?? "");
  const [userEmail, setUserEmail] = useState(user?.email ?? "");
  const [userTimezone, setUserTimezone] = useState(user?.timezone ?? "");

  const [loadingCalendars, setLoadingCalendars] = useState<boolean>(true);
  const [calendars, setCalendars] = useState<any>({ list: [] });

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    setLoadingCalendars(true);

    API.calendars
      .list(1, signal)
      .then((res: any) => {
        setCalendars({ list: res.data });
        setLoadingCalendars(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load calendar settings");
      });

    return () => {
      controller.abort();
    };
  }, []);

  const [copiedURL, setCopiedURL] = useState(false);
  const onCopyCalendarURL = () => {
    navigator.clipboard.writeText(calendars.list[0]?.webcal_url);
    setCopiedURL(true);

    // TODO: Show Toast
  };

  const onOpenCalendarURL = (service: string) => {
    let redirectTo = "";

    if (service === "google") {
      redirectTo = `https://www.google.com/calendar/render?cid=${calendars.list[0]?.webcal_url}`;
    } else {
      redirectTo = calendars.list[0]?.webcal_url;
    }

    window.open(redirectTo, "_blank");
  };

  return (
    <PageDetails title="Settings">
      <FormField
        id="user_name"
        title="Name"
        type="text"
        value={userName}
        onChange={(e: any) => setUserName(e.target.value)}
        required={true}
        disabled={true}
      />
      <FormField
        id="user_email"
        title="Email address"
        type="email"
        value={userEmail}
        onChange={(e: any) => setUserEmail(e.target.value)}
        required={true}
        disabled={true}
      />
      <FormField
        id="user_timezone"
        title="Default Timezone"
        type="text"
        value={userTimezone}
        onChange={(e: any) => setUserTimezone(e.target.value)}
        required={true}
        disabled={true}
      />
      <FormField
        id="calendar_subscribe"
        title="Calendar Subscription"
        type="text"
        value={calendars.list[0]?.webcal_url ?? ""}
        onChange={(e: any) => setUserTimezone(e.target.value)}
        readOnly={true}
        footer={
          <>
            {!loadingCalendars && calendars.list.length > 0 && (
              <div className="flex mt-3 mb-5 sm:flex-row flex-col sm:space-x-2 sm:space-y-0 space-x-0 space-y-2">
                <button
                  onClick={() => onCopyCalendarURL()}
                  type="button"
                  className="py-3 px-4 group inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                >
                  {copiedURL ? (
                    <CheckIcon className="h-5 w-5 text-blue-600" />
                  ) : (
                    <ClipboardIcon className="h-5 w-5" />
                  )}
                  <span>{copiedURL ? "Copied" : "Copy"}</span>
                </button>
                <button
                  onClick={() => onOpenCalendarURL("google")}
                  type="button"
                  className="py-3 px-4 group inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                >
                  <CalendarDaysIcon className="h-5 w-5" />
                  <span>Google Calendar</span>
                </button>
                <button
                  onClick={() => onOpenCalendarURL("device")}
                  type="button"
                  className="py-3 px-4 group inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                >
                  <LinkIcon className="h-5 w-5" />
                  <span>Default Calendar</span>
                </button>
              </div>
            )}
          </>
        }
      />
    </PageDetails>
  );
}
