import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import Logo from "./../assets/logo_icon.png";

import API from "../helpers/api.ts";
import Page from "../components/page";
import { useAuth } from "../hooks/auth";

export default function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, onLogin, onLogout } = useAuth();

  const [redirectTo, setRedirectTo] = useState("/");
  const [loggingOut, setLoggingOut] = useState<boolean>(false);

  useEffect(() => {
    setRedirectTo(state?.from ?? "/");
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (user && !loggingOut) {
      setLoggingOut(true);

      API.auth
        .logout(signal)
        .then(() => {
          onLogout();
          setLoggingOut(false);
        })
        .catch(() => {
          onLogout();
          setLoggingOut(false);
        });
    }

    return () => {
      controller.abort();
    };
  }, []);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    API.auth
      .login(email, password)
      .then((result) => {
        setLoading(false);
        onLogin(result);
        navigate(redirectTo, { replace: true });
      })
      .catch((error) => {
        setPassword("");
        setLoading(false);
        setError(
          error?.http_status === 400
            ? "Incorrect email or password"
            : "Something went wrong, please try again later"
        );
      });
  };

  return (
    <Page
      title="Sign In"
      hideNav={true}
      hideHeader={true}
      containerStyle="h-full w-full max-w-md mx-auto p-6"
    >
      <div className="mt-20 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
        <div className="p-4 sm:p-7">
          {loggingOut ? (
            <div className="text-center h-[50vh] flex justify-center items-center">
              <div
                className="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-center mt-2">
                <img
                  src={Logo}
                  className="h-10 w-10 mr-2"
                  alt="ClientBase Logo"
                />
                <p className="text-xl font-semibold dark:text-white">
                  ClientBase
                </p>
              </div>
              <div className="mt-8">
                <form onSubmit={(e) => handleFormSubmit(e)}>
                  <div className="grid gap-y-4">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm mb-2 dark:text-white"
                      >
                        Email address
                      </label>
                      <div className="relative">
                        <input
                          disabled={loading}
                          type="email"
                          id="email"
                          name="email"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="password"
                          className="block text-sm mb-2 dark:text-white"
                        >
                          Password
                        </label>
                      </div>
                      <div className="relative">
                        <input
                          disabled={loading}
                          type="password"
                          id="password"
                          name="password"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <button
                      disabled={loading}
                      type="submit"
                      className="mt-10 w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      {loading && (
                        <span
                          className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        />
                      )}
                      {loading ? "Loading" : "Sign in"}
                    </button>
                    {error && (
                      <div className="flex justify-center mt-2">
                        <ExclamationCircleIcon className="size-[24px] text-red-800 dark:bg-red-900 dark:text-red-400" />
                        <p className="text-sm text-red-600 text-center flex items-center m-0 pl-1 font-semibold">
                          {error}
                        </p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}
