import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Dates, { currentTimezoneGMT } from "../helpers/dates.ts";
import { useLocalStorage } from "../hooks/localStorage.ts";
import API from "./../helpers/api.ts";
import Page from "./../components/page";
import TableView from "../components/tableView";
import Modal from "../components/modal";
import ReadMore from "../components/readMore.tsx";

import { CalendarDaysIcon } from "@heroicons/react/24/outline";

const filterOptions = [
  {
    id: "all",
    title: "All viewings",
    value: "all",
  },
  {
    id: "upcoming",
    title: "Upcoming viewings",
    value: "upcoming",
  },
  {
    id: "past",
    title: "Past viewings",
    value: "past",
  },
];

const sortOptions = [
  {
    id: "starts_at_asc",
    alt_id: "date",
    title: "Viewing date",
    field: "starts_at",
    dir: "asc",
  },
  {
    id: "created_at_desc",
    alt_id: "latest",
    title: "Latest",
    field: "created_at",
    dir: "desc",
  },
  {
    id: "created_at_asc",
    alt_id: "oldest",
    title: "Oldest",
    field: "created_at",
    dir: "asc",
  },
  {
    id: "updated_at_desc",
    alt_id: "updated",
    title: "Last update",
    field: "updated_at",
    dir: "desc",
  },
];

const tableHeadings = [
  {
    id: "property",
    nested: "title",
    title: "Property",
    type: "link",
    linkConfig: {
      baseURL: "/properties/",
      id: "property_id",
      cache: "property",
    },
    width: "max",
  },
  {
    id: "viewer",
    nested: "name",
    title: "Viewer",
    type: "link",
    linkConfig: {
      baseURL: "/clients/",
      id: "viewer_id",
      cache: "viewer",
    },
    default: "None",
    width: "max",
  },
  {
    id: "date_formatted",
    title: "Date",
    type: "badge",
    width: "fit",
  },
  {
    id: "times_formatted",
    title: `Time (${currentTimezoneGMT})`,
    type: "badge",
    width: "fit",
    styles: { paddingRight: 0 },
  },
];

const rowOptions = [
  {
    id: "view",
    title: "View details",
  },
];

export default function Viewings() {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteDetails, setDeleteDetails] = useState<any>(null);
  const [deletingViewing, setDeletingViewing] = useState<boolean>(false);

  const [loadingViewings, setLoadingViewings] = useState<boolean>(true);
  const [viewings, setViewings] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<any>(null);

  const [sortBy, setSortBy] = useLocalStorage(
    "prefs.viewings.sort",
    "starts_at"
  );
  const [sortDir, setSortDir] = useLocalStorage("prefs.viewings.dir", "asc");

  const [filterBy, setFilterBy] = useLocalStorage(
    "prefs.viewings.filter",
    "upcoming"
  );

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    setLoadingViewings(true);

    API.viewings
      .list(currentPage, sortBy, sortDir, filterBy, searchTerm, signal)
      .then((res: any) => {
        res.data.forEach((data: any) => {
          data.date_formatted = Dates(data.starts_at).format("ll");
          data.times_formatted =
            Dates(data.starts_at).local().format("HH:mm") +
            " - " +
            Dates(data.ends_at).local().format("HH:mm");
        });

        setPagination(res.pagination);
        setViewings(res.data);
        setLoadingViewings(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load viewing list");
      });

    return () => {
      controller.abort();
    };
  }, [refresh, currentPage, sortBy, sortDir, filterBy, searchTerm]);

  const handleSort = (sortValue: any) => {
    setSortBy(sortValue.field);
    setSortDir(sortValue.dir);

    setCurrentPage(1);
  };

  const handleFilter = (filterValue: any) => {
    setFilterBy(filterValue.id);

    setCurrentPage(1);
  };

  const handleRowOption = (optionId: string, viewingDetails: any) => {
    if (optionId === "view") {
      navigate(`/viewings/${viewingDetails?.id}`, {
        state: { cachedDetails: viewingDetails },
      });
    }
  };

  const handleDeleteViewing = (viewingDetails: any) => {
    setDeleteDetails(viewingDetails);
    setShowDeleteModal(true);
  };

  const onDeleteViewing = () => {
    setDeletingViewing(true);

    API.viewings
      .delete(deleteDetails?.id)
      .then(() => {
        setRefresh(!refresh);
        setCurrentPage(1);
        setShowDeleteModal(false);
        setDeleteDetails(null);
        setDeletingViewing(false);
        toast.success("Viewing deleted successfully");
      })
      .catch(() => {
        setShowDeleteModal(false);
        setDeleteDetails(null);
        setDeletingViewing(false);
        toast.error("Failed to delete viewing");
      });
  };

  const handleAddNewViewing = () => {
    navigate("/viewings/new");
  };

  return (
    <Page
      title="Viewings"
      sort={sortOptions}
      handleSort={handleSort}
      currentSort={`${sortBy}_${sortDir}`}
      filter={filterOptions}
      handleFilter={handleFilter}
      currentFilter={filterBy}
      onCreate={handleAddNewViewing}
      createTxt="Add viewing"
      searchHint="Search for viewings"
      handleSearch={setSearchTerm}
      currentSearch={searchTerm}
    >
      <Modal
        id="delete_viewing"
        title="Delete viewing"
        confirmTxt="Delete"
        confirmStyle="bg-red-500 hover:bg-red-600"
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onDeleteViewing}
        loading={deletingViewing}
      >
        <p className="mt-1 text-gray-800 dark:text-gray-400">
          Are you sure you want to remove{" "}
          <span className="italic font-semibold">{deleteDetails?.title}</span>{" "}
          from your list of viewings?
        </p>
        <ReadMore id="delete_viewing" key={deleteDetails?.id}>
          <p>Deleting this viewing will remove it from your synced calendar.</p>
        </ReadMore>
      </Modal>
      <TableView
        refreshKey={currentPage}
        headings={tableHeadings}
        loading={loadingViewings}
        data={viewings}
        options={rowOptions}
        onOption={handleRowOption}
        onDelete={handleDeleteViewing}
        emptyStateIcon={<CalendarDaysIcon className="h-20 w-20" />}
        emptyStateTitle={
          filterBy === "upcoming" ? "No upcoming viewings" : "No viewings added"
        }
        emptyStateDesc="Add a viewing to get started with managing appointments with clients"
        emptyStateActionTitle="Add viewing"
        emptyStateOnAction={handleAddNewViewing}
        pagination={pagination}
        handlePagination={setCurrentPage}
        searchTerm={searchTerm}
        onClearSearch={() => setSearchTerm("")}
      />
    </Page>
  );
}
