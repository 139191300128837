import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/auth";

export default function ProtectedRoute({ children }: { children: any }) {
  const { user } = useAuth();
  const location = useLocation();

  // check is user is authenticated and expires_at not reached (in seconds since epoch)
  if (user && user?.expires_at > Math.round(Date.now() / 1000)) {
    return children;
  }

  // user is not authenticated
  return (
    <Navigate
      to="/login"
      state={{ from: location.pathname + (location?.search ?? "") }}
    />
  );
}
