import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";

import API from "../helpers/api";
import PageDetails, { FormField } from "./../components/pageDetails";

// TODO: Improve
const BREADCRUMB = {
  root: {
    title: "Properties",
    url: "/properties",
  },
  crumbs: [
    {
      isCurrent: true,
      title: "Manage Property",
      url: "#",
    },
  ],
};

export default function PropertyDetails() {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState<string>("");
  const [loadingPropertyDetails, setLoadingPropertyDetails] =
    useState<boolean>(true);

  const [propertyTitle, setPropertyTitle] = useState<string>("");
  const [propertyAddress, setPropertyAddress] = useState<string>("");
  const [propertyNotes, setPropertyNotes] = useState<string>("");
  const [propertyFeatures, setPropertyFeatures] = useState<any>({
    features: [],
  });

  const [propertyOwnerValue, setPropertyOwnerValue] = useState<any>(null);
  const [propertyOwnerOptions, setPropertyOwnerOptions] = useState<any>({
    list: [],
  });

  const [propertyTenantValue, setPropertyTenantValue] = useState<any>(null);
  const [propertyTenantOptions, setPropertyTenantOptions] = useState<any>({
    list: [],
  });

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    if (state?.cachedDetails && state?.cachedDetails?.id === id) {
      setPropertyTitle(state.cachedDetails.title ?? "");
      setPropertyAddress(state.cachedDetails.address ?? "");
      setPropertyNotes(state.cachedDetails.notes ?? "");
      setPropertyFeatures({ features: state.cachedDetails.features ?? [] });

      const tempOwnerId = state.cachedDetails.owner?.id;
      const tempOwnerName = state.cachedDetails.owner?.name;
      setPropertyOwnerValue({ value: tempOwnerId, label: tempOwnerName });

      const tempTenantId = state.cachedDetails.tenant?.id;
      const tempTenantName = state.cachedDetails.tenant?.name;
      setPropertyTenantValue(
        state.cachedDetails.tenant_id
          ? { value: tempTenantId, label: tempTenantName }
          : null
      );

      setLoadingPropertyDetails(false);
    } else if (id === "new") {
      setLoadingPropertyDetails(false);
    } else {
      setLoadingPropertyDetails(true);

      API.properties
        .get(id as string, signal)
        .then((res: any) => {
          setPropertyTitle(res.title);
          setPropertyAddress(res.address);
          setPropertyNotes(res.notes);
          setPropertyFeatures({ features: res.features ?? [] });

          const tempOwnerId = res.owner.id;
          const tempOwnerName = res.owner.name;
          setPropertyOwnerValue({ value: tempOwnerId, label: tempOwnerName });

          const tempTenantId = res.tenant?.id;
          const tempTenantName = res.tenant?.name;
          setPropertyTenantValue(
            res.tenant_id
              ? { value: tempTenantId, label: tempTenantName }
              : null
          );

          setLoadingPropertyDetails(false);
        })
        .catch(() => {
          if (!signal.aborted) toast.error("Failed to load property details");
        });
    }

    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    if (id === "new") {
      setPageTitle("Add Property");
    } else {
      setPageTitle(
        propertyTitle.trim().length !== 0 ? propertyTitle : "Edit Property"
      );
    }
  }, [propertyTitle]);

  /*const [loadingPropertyOwners, setLoadingPropertyOwners] =
    useState<boolean>(false);
  const [propertyOwnerSearch, setPropertyOwnerSearch] = useState<string>("");
  const [propertyOwnerSearchValue] = useDebounce(propertyOwnerSearch, 1000);

  const [loadingPropertyTenants, setLoadingPropertyTenants] =
    useState<boolean>(false);
  const [propertyTenantSearch, setPropertyTenantSearch] = useState<string>("");
  const [propertyTenantSearchValue] = useDebounce(propertyTenantSearch, 1000);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();

    fetchClients("owners", propertyOwnerSearchValue, controller);

    return () => {
      controller.abort();
    };
  }, [propertyOwnerSearchValue]);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();

    fetchClients("tenants", propertyTenantSearchValue, controller);

    return () => {
      controller.abort();
    };
  }, [propertyTenantSearchValue]);


  // TODO: Improve this code
  const fetchClients = (type: string, searchTxt: string, controller: any) => {
    if (type === "owners") {
      setLoadingPropertyOwners(true);
    } else {
      setLoadingPropertyTenants(true);
    }

    API.clients
      .searchByName(searchTxt, controller.signal)
      .then((res: any) => {
        const tempClients: {
          value: string;
          label: string;
          disabled?: boolean;
        }[] = [];

        res.data.forEach((client: any) => {
          tempClients.push({
            value: client.id,
            label: client.name,
          });
        });

        if (type === "owners") {
          setPropertyOwnerOptions({ list: tempClients });
          setLoadingPropertyOwners(false);
        } else {
          setPropertyTenantOptions({ list: tempClients });
          setLoadingPropertyTenants(false);
        }
      })
      .catch(() => {
        if (!controller.signal.aborted) {
          if (type === "owners") {
            toast.error("Failed to search owners");
            setLoadingPropertyOwners(false);
          } else {
            toast.error("Failed to search tenants");
            setLoadingPropertyTenants(false);
          }
        }
      });
  };*/

  const [loadingPropertyClients, setLoadingPropertyClients] =
    useState<boolean>(false);
  const [propertyClientSearch, setPropertyClientSearch] = useState<string>("");
  const [propertyClientSearchValue] = useDebounce(propertyClientSearch, 1000);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();

    fetchClients(propertyClientSearchValue, controller);

    return () => {
      controller.abort();
    };
  }, [propertyClientSearchValue]);

  // TODO: Improve this code
  const fetchClients = (searchTxt: string, controller: any) => {
    setLoadingPropertyClients(true);

    API.clients
      .searchByName(searchTxt, controller.signal)
      .then((res: any) => {
        const tempClients: {
          value: string;
          label: string;
          disabled?: boolean;
        }[] = [];

        res.data.forEach((client: any) => {
          tempClients.push({
            value: client.id,
            label: client.name,
          });
        });

        setPropertyOwnerOptions({ list: tempClients });
        setPropertyTenantOptions({ list: tempClients });

        setLoadingPropertyClients(false);
      })
      .catch(() => {
        if (!controller.signal.aborted) {
          toast.error("Failed to search clients");
          setLoadingPropertyClients(false);
        }
      });
  };

  const onSaveChanges = () => {
    if (propertyOwnerValue === null) {
      toast.error("Property owner is required");
      return;
    }

    setLoadingPropertyDetails(true);

    // TODO: Only send changed fields
    const propertyDetails = {
      title: propertyTitle,
      address: propertyAddress,
      notes: propertyNotes,
      owner_id: propertyOwnerValue?.value,
      tenant_id: propertyTenantValue?.value ?? null,
      features:
        propertyFeatures.features.length > 0 ? propertyFeatures.features : null, // TODO: Implement property features
    };

    if (id === "new") {
      API.properties
        .add(propertyDetails)
        .then(() => {
          toast.success("Property added successfully");
          navigate("/properties");
        })
        .catch(() => {
          toast.error("Failed to add property");
          setLoadingPropertyDetails(false);
        });
    } else {
      API.properties
        .update(id as string, propertyDetails)
        .then(() => {
          toast.success("Property updated successfully");
          navigate("/properties");
        })
        .catch(() => {
          toast.error("Failed to update property");
          setLoadingPropertyDetails(false);
        });
    }
  };

  return (
    <PageDetails
      title={pageTitle}
      loading={loadingPropertyDetails}
      onDiscard={() => navigate("/properties")}
      onSave={onSaveChanges}
      saveTitle={id === "new" ? "Add property" : "Save changes"}
      disableActions={
        loadingPropertyClients /*loadingPropertyOwners || loadingPropertyTenants*/
      }
      breadcrumb={BREADCRUMB}
    >
      <FormField
        id="property_title"
        title="Title"
        type="text"
        value={propertyTitle}
        onChange={(e: any) => setPropertyTitle(e.target.value)}
        required={true}
      />
      <FormField
        id="property_address"
        title="Address"
        type="text"
        value={propertyAddress}
        onChange={(e: any) => setPropertyAddress(e.target.value)}
        required={true}
      />
      <FormField
        id="property_owner"
        title="Owner"
        type="searchable"
        value={propertyOwnerValue}
        onChange={setPropertyOwnerValue}
        required={true}
        selectConfig={{
          options: propertyOwnerOptions.list,
          loading: loadingPropertyClients, //loadingPropertyOwners,
          onSearch: (e: any) => setPropertyClientSearch(e.target.value), //setPropertyOwnerSearch(e.target.value),
        }}
      />
      <FormField
        id="property_tenant"
        title="Tenant"
        type="searchable"
        value={propertyTenantValue}
        onChange={setPropertyTenantValue}
        selectConfig={{
          options: propertyTenantOptions.list,
          loading: loadingPropertyClients, //loadingPropertyTenants,
          onSearch: (e: any) => setPropertyClientSearch(e.target.value), //setPropertyTenantSearch(e.target.value),
        }}
      />
      <FormField
        id="property_notes"
        title="Notes"
        type="textarea"
        value={propertyNotes}
        onChange={(e: any) => setPropertyNotes(e.target.value)}
      />
    </PageDetails>
  );
}
