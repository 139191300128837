import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";

import API from "../helpers/api";
import Dates, { currentTimezone, currentTimezoneGMT } from "../helpers/dates";

import PageDetails, { FormField } from "./../components/pageDetails";

// TODO: Improve
const BREADCRUMB = {
  root: {
    title: "Viewings",
    url: "/viewings",
  },
  crumbs: [
    {
      isCurrent: true,
      title: "Manage Viewing",
      url: "#",
    },
  ],
};

export default function ViewingDetails() {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState<string>("");
  const [loadingViewingDetails, setLoadingViewingDetails] =
    useState<boolean>(true);

  const [viewingTitle, setViewingTitle] = useState<string>("");
  const [viewingNotes, setViewingNotes] = useState<string>("");

  const [viewingPropertyValue, setViewingPropertyValue] = useState<any>(null);
  const [viewingPropertyOptions, setPropertyOwnerOptions] = useState<any>({
    list: [],
  });

  const [viewingViewerValue, setViewingViewerValue] = useState<any>(null);
  const [viewingViewerOptions, setViewingViewerOptions] = useState<any>({
    list: [],
  });

  const [minDate, setMinDate] = useState<string>("");
  const [viewingDate, setViewingDate] = useState<string>("");

  const [viewingStartTime, setViewingStartTime] = useState<string>("");
  const [viewingEndTime, setViewingEndTime] = useState<string>("");

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    if (state?.cachedDetails && state?.cachedDetails?.id === id) {
      setViewingTitle(state.cachedDetails.property?.title ?? "");
      setViewingNotes(state.cachedDetails.notes ?? "");

      const tempPropertyId = state.cachedDetails.property?.id;
      const tempPropertyTitle = state.cachedDetails.property?.title;
      setViewingPropertyValue({
        value: tempPropertyId,
        label: tempPropertyTitle,
      });

      const tempViewerId = state.cachedDetails.viewer?.id;
      const tempViewerName = state.cachedDetails.viewer?.name;
      setViewingViewerValue(
        state.cachedDetails.viewer_id
          ? { value: tempViewerId, label: tempViewerName }
          : null
      );

      setMinDate(
        Dates(state.cachedDetails.starts_at).local().format("YYYY-MM-DD")
      );
      setViewingDate(
        Dates(state.cachedDetails.starts_at).local().format("YYYY-MM-DD")
      );

      setViewingStartTime(
        Dates(state.cachedDetails.starts_at).local().format("HH:mm")
      );
      setViewingEndTime(
        Dates(state.cachedDetails.ends_at).local().format("HH:mm")
      );

      setLoadingViewingDetails(false);
    } else if (id === "new") {
      setMinDate(Dates().format("YYYY-MM-DD"));
      setLoadingViewingDetails(false);
    } else {
      setLoadingViewingDetails(true);

      API.viewings
        .get(id as string, signal)
        .then((res: any) => {
          setViewingTitle(res.property?.title);
          setViewingNotes(res.notes);

          const tempPropertyId = res.property?.id;
          const tempPropertyTitle = res.property?.title;
          setViewingPropertyValue({
            value: tempPropertyId,
            label: tempPropertyTitle,
          });

          const tempViewerId = res.viewer?.id;
          const tempViewerName = res.viewer?.name;
          setViewingViewerValue(
            res.viewer_id
              ? { value: tempViewerId, label: tempViewerName }
              : null
          );

          setMinDate(Dates(res.starts_at).local().format("YYYY-MM-DD"));
          setViewingDate(Dates(res.starts_at).local().format("YYYY-MM-DD"));

          setViewingStartTime(Dates(res.starts_at).local().format("HH:mm"));
          setViewingEndTime(Dates(res.ends_at).local().format("HH:mm"));

          setLoadingViewingDetails(false);
        })
        .catch(() => {
          if (!signal.aborted) toast.error("Failed to load viewing details");
        });
    }

    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    if (id === "new") {
      setPageTitle("Add Viewing");
    } else {
      setPageTitle(
        viewingTitle.trim().length !== 0 ? viewingTitle : "Edit Viewing"
      );
    }
  }, [viewingTitle]);

  const [loadingViewingProperties, setLoadingViewingProperties] =
    useState<boolean>(false);
  const [viewingPropertySearch, setViewingPropertySearch] =
    useState<string>("");
  const [viewingPropertySearchValue] = useDebounce(viewingPropertySearch, 1000);

  const [loadingViewingViewers, setLoadingViewingViewers] =
    useState<boolean>(false);
  const [viewingViewerSearch, setPropertyTenantSearch] = useState<string>("");
  const [viewingViewerSearchValue] = useDebounce(viewingViewerSearch, 1000);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();

    fetchProperties(viewingPropertySearchValue, controller);

    return () => {
      controller.abort();
    };
  }, [viewingPropertySearchValue]);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();

    fetchClients(viewingViewerSearchValue, controller);

    return () => {
      controller.abort();
    };
  }, [viewingViewerSearchValue]);

  // TODO: Improve this code
  const fetchProperties = (searchTxt: string, controller: any) => {
    setLoadingViewingProperties(true);

    API.properties
      .searchByTitle(searchTxt, controller.signal)
      .then((res: any) => {
        const tempProperties: {
          value: string;
          label: string;
          disabled?: boolean;
        }[] = [];

        res.data.forEach((property: any) => {
          tempProperties.push({
            value: property.id,
            label: property.title,
          });
        });

        setPropertyOwnerOptions({ list: tempProperties });
        setLoadingViewingProperties(false);
      })
      .catch(() => {
        if (!controller.signal.aborted) {
          toast.error("Failed to search properties");
          setLoadingViewingProperties(false);
        }
      });
  };

  // TODO: Improve this code
  const fetchClients = (searchTxt: string, controller: any) => {
    setLoadingViewingViewers(true);

    API.clients
      .searchByName(searchTxt, controller.signal)
      .then((res: any) => {
        const tempClients: {
          value: string;
          label: string;
          disabled?: boolean;
        }[] = [];

        res.data.forEach((client: any) => {
          tempClients.push({
            value: client.id,
            label: client.name,
          });
        });

        setViewingViewerOptions({ list: tempClients });
        setLoadingViewingViewers(false);
      })
      .catch(() => {
        if (!controller.signal.aborted) {
          toast.error("Failed to search viewers");
          setLoadingViewingViewers(false);
        }
      });
  };

  const onSaveChanges = () => {
    if (viewingPropertyValue === null) {
      toast.error("Viewing property is required");
      return;
    }

    setLoadingViewingDetails(true);

    // TODO: Send UTC format instead
    const tempViewingStartsAt = Dates(viewingDate + " " + viewingStartTime)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    const tempViewingEndsAt = Dates(viewingDate + " " + viewingEndTime)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    // TODO: Only send changed fields
    const viewingDetails = {
      title: viewingPropertyValue?.label,
      notes: viewingNotes,
      property_id: viewingPropertyValue?.value,
      viewer_id: viewingViewerValue?.value ?? null,
      starts_at: tempViewingStartsAt,
      ends_at: tempViewingEndsAt,
    };

    if (id === "new") {
      API.viewings
        .add(viewingDetails)
        .then(() => {
          toast.success("Viewing added successfully");
          navigate("/viewings");
        })
        .catch(() => {
          toast.error("Failed to add viewing");
          setLoadingViewingDetails(false);
        });
    } else {
      API.viewings
        .update(id as string, viewingDetails)
        .then(() => {
          toast.success("Viewing updated successfully");
          navigate("/viewings");
        })
        .catch(() => {
          toast.error("Failed to update viewing");
          setLoadingViewingDetails(false);
        });
    }
  };

  return (
    <PageDetails
      title={pageTitle}
      description={
        <>
          Viewing times are displayed in{" "}
          <i className="font-medium">
            {currentTimezone} ({currentTimezoneGMT})
          </i>
        </>
      }
      loading={loadingViewingDetails}
      onDiscard={() => navigate("/viewings")}
      onSave={onSaveChanges}
      saveTitle={id === "new" ? "Add viewing" : "Save changes"}
      disableActions={loadingViewingProperties || loadingViewingViewers}
      breadcrumb={BREADCRUMB}
    >
      <FormField
        id="viewing_property"
        title="Property"
        type="searchable"
        value={viewingPropertyValue}
        onChange={setViewingPropertyValue}
        required={true}
        selectConfig={{
          options: viewingPropertyOptions.list,
          loading: loadingViewingProperties,
          onSearch: (e: any) => setViewingPropertySearch(e.target.value),
        }}
      />
      <FormField
        id="viewing_date"
        title="Date"
        type="date"
        value={viewingDate}
        minValue={minDate}
        onChange={(e: any) => setViewingDate(e.target.value)}
        required={true}
      />
      <FormField
        id="viewing_start_time"
        title="Start time"
        type="time"
        value={viewingStartTime}
        onChange={(e: any) => setViewingStartTime(e.target.value)}
        required={true}
      />
      <FormField
        id="viewing_end_time"
        title="End time"
        type="time"
        value={viewingEndTime}
        minValue={viewingStartTime}
        onChange={(e: any) => setViewingEndTime(e.target.value)}
        required={true}
      />
      <FormField
        id="viewing_viewer"
        title="Viewer"
        type="searchable"
        value={viewingViewerValue}
        onChange={setViewingViewerValue}
        selectConfig={{
          options: viewingViewerOptions.list,
          loading: loadingViewingViewers,
          onSearch: (e: any) => setPropertyTenantSearch(e.target.value),
        }}
      />
      <FormField
        id="viewing_notes"
        title="Notes"
        type="textarea"
        value={viewingNotes}
        onChange={(e: any) => setViewingNotes(e.target.value)}
      />
    </PageDetails>
  );
}
