export default function ReadMore({
  id,
  children,
  moreText,
  lessText,
}: {
  id: string;
  children: any;
  moreText?: string;
  lessText?: string;
}) {
  return (
    <>
      <div
        id={`readmore_heading_${id}`}
        className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
        aria-labelledby={`readmore_${id}`}
      >
        <div className="text-gray-500 dark:text-neutral-400">{children}</div>
      </div>
      <p className="mt-2">
        <button
          type="button"
          className="hs-collapse-toggle inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400"
          id={`readmore_${id}`}
          data-hs-collapse={`#readmore_heading_${id}`}
        >
          <span className="hs-collapse-open:hidden">
            {moreText ?? "Read more"}
          </span>
          <span className="hs-collapse-open:block hidden">
            {lessText ?? "Read less"}
          </span>
          <svg
            className="hs-collapse-open:rotate-180 flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m6 9 6 6 6-6"></path>
          </svg>
        </button>
      </p>
    </>
  );
}
